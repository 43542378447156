import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Faq } from '@/components/faq/Faq';
import { BRAV_LINK } from '@/constants/links';

export default function FAQS() {
  const pageTitle = 'FAQ';

  const Banner = () => (
    <StaticImage
      src="../images/banners/faq.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const imageSize = 120;

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div
        style={{ marginBottom: '150px', marginTop: '100px' }}
        className="container"
      >
        <div className="row">
          <Faq
            title="Come posso pagare la sosta?"
            image={
              <StaticImage
                src="../images/faq/icona1.png"
                alt="faq-1"
                width={imageSize}
                quality={100}
              />
            }
            text={
              <p>
                Pagare la sosta con GPS è semplicissimo! Scarica la nostra app
                GPS APP. I sistemi di pagamento accettati sono: carte di credito
                dei circuiti Visa, American Express, Mastercard o Paypal,
                SisalPay, PostePay e Satispay.
              </p>
            }
            buttons={[{ text: 'Paga la sosta', link: '/paga-la-sosta' }]}
          />
          <Faq
            title="Come posso sottoscrivere un abbonamento?"
            image={
              <StaticImage
                src="../images/faq/icona2.png"
                alt="faq-1"
                width={imageSize}
                quality={100}
              />
            }
            text={
              <p>
                Per sottoscrivere un abbonamento clicca sulla pagina
                “Abbonamenti e Pass” del nostro sito, il sistema ti guiderà
                sulle procedure da eseguire. Il nostro back-office esaminerà la
                tua richiesta e riceverai una notifica non appena verrà
                accettata. Inoltre, ti ricordiamo che puoi effettuare la
                procedura anche presso i nostri front office, aperti dal lunedì
                al sabato dalle 8.00 alle 12.00 e il mercoledì dalle 8.00 alle
                16.00.
              </p>
            }
            buttons={[]}
          />
          <Faq
            title="Posso prenotare un parcheggio in struttura?"
            image={
              <StaticImage
                src="../images/faq/icona3.png"
                alt="faq-1"
                width={imageSize}
                quality={100}
              />
            }
            text={
              <p>
                Il servizio di prenotazione, sarà presto disponibile per tutti i
                parcheggi a sbarra della città. In occasione di VICENZA ORO è
                possibile prenotare i parcheggi del quartiere fieristico in
                "home" nella sezione "prenota la sosta a Fiera Vicenza".
              </p>
            }
            buttons={[]}
          />
          <Faq
            title="Dove posso parcheggiare la mia bici in sicurezza?"
            image={
              <StaticImage
                src="../images/faq/icona4.png"
                alt="faq-1"
                width={imageSize}
                quality={100}
              />
            }
            text={
              <p>
                Puoi parcheggiare la tua bici in totale sicurezza, all'interno
                dei nostri Bicipark, infatti è presente un sistema di cancelli
                ad apertura controllata e autorizzata solo ai titolari di
                apposita tessera e un sistema di videocontrollo integrale
                dell'intera area.
              </p>
            }
            buttons={[
              {
                text: 'Trova Bicipark',
                link: 'https://maps.app.goo.gl/UedmmCgvSCzhvemB6',
              },
            ]}
          />
          <Faq
            title="Se risiedo in zona fiera devo pagare la sosta durante gli eventi fieristici?"
            image={
              <StaticImage
                src="../images/faq/icona5.png"
                alt="faq-1"
                width={imageSize}
                quality={100}
              />
            }
            text={
              <p>
                Se sei un residente, domiciliato o lavoratore presso le imprese
                ubicate in questa area, puoi richiedere un permesso di sosta a
                titolo gratuito. Puoi seguire la procedura di richiesta andando
                sulla pagina “Abbonamenti e Pass” del nostro sito oppure
                recandoti presso i nostri front office.
              </p>
            }
            buttons={[
              {
                text: 'Richiedi pass',
                link: BRAV_LINK,
              },
            ]}
          />
          <Faq
            title="Sono un camperista in transito nella città di Vicenza, dove posso sostare con il mio camper?"
            image={
              <StaticImage
                src="../images/faq/icona6.png"
                alt="faq-1"
                width={imageSize}
                quality={100}
              />
            }
            text={
              <p>
                A Vicenza puoi sostare nei parcheggi di interscambio Stadio e
                Cricoli. Inoltre, avrai a disposizione un impian- to di scarico
                delle acque nere ed un rubinetto con acqua potabile per i
                rifornimenti dei serbatori d'acqua dei camper.
              </p>
            }
            buttons={[
              {
                text: 'Parcheggia a Cricoli Park',
                link: 'https://goo.gl/maps/5PebQXj53uUjXcwW9',
              },
              {
                text: 'Parcheggia a Park Stadio',
                link: 'https://goo.gl/maps/KpyuNw7phQ3ZWATF8',
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}
