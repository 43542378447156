import { Link } from 'gatsby';
import React, { FC, ReactNode } from 'react';

interface FaqProps {
  title?: string;
  image: ReactNode;
  text?: JSX.Element;
  buttons: {
    text: string;
    link: string;
  }[];
}

export const Faq: FC<FaqProps> = ({
  title,
  image,
  text,
  buttons,
}: FaqProps) => {
  return (
    <div
      style={{ maxWidth: '400px' }}
      className="col-12 col-lg-4 mb-5 pb-5 mx-auto"
    >
      <div className="bg-secondary bordered mx-auto">
        <div className="text-center p-4">{image}</div>
      </div>
      <h1
        style={{ fontSize: '22px' }}
        className="text-primary font-rubik pt-3 pb-3"
      >
        {title}
      </h1>
      <p className="text-secondary font-oxygen mb-4">{text}</p>
      {buttons.map((button, index) =>
        button.link.includes('https') ? (
          <a href={button.link} key={index} target="_blank">
            <button className="btn btn-primary mb-3">{button.text}</button>
          </a>
        ) : (
          <Link to={button.link} key={index}>
            <button className="btn btn-primary mb-3">{button.text}</button>
          </Link>
        ),
      )}
    </div>
  );
};
